<template>
  <div class="h-full w-screen">
    <PageSection
      reduced
      class="h-64"
    >
      <SectionText>
        Our youth boxing classes give young children and teens the opportunity to <span class="text-primary font-normal">learn new skills, create healthy habits, socialise with others and gain confidence</span> all while improving their fitness and strength.
      </SectionText>
      <SectionText>
        Our classes provide a safe, inclusive environment, catering for all levels of ability.
      </SectionText>
      <SectionText>
        For the younger kids (6-9years) we coach boxing classes on Monday and Friday at 4pm with a <span class="text-primary font-normal">KID-FIT</span> option at 4pm on Wednesday.
      </SectionText>
      <SectionText>
        Our <span class="text-primary font-normal">teenage boxing classes</span> are on Monday, Wednesday & Friday at 6pm. All levels of ability and fitness are very welcome.
      </SectionText>
      <button
        class="w-auto mt-4"
        @click="
          toggleModal({
            modalName: 'contact',
            subject: $route.meta.title,
            show: true,
          })
        "
      >
        <div
          class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"
          :aria-label="`Contact us about Kids training at UNIT 3`"
        >
          Contact us about Kids training at UNIT 3 
        </div>
      </button>
    </PageSection>
    <LoadingState v-if="getImagesIsLoading" />
    <ErrorState
      v-else-if="getImagesIsError"
      :error="getImagesError"
      :retry="fetchImages"
    />
    <Gallery
      v-else-if="getImages && getImages.length"
      :images="getImages"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ErrorState, LoadingState, PageSection, SectionText, Gallery } from '@/pages/shared/index';
import imagesStore from '@/infrastructure/store/modules/images/';

export default {
  name: 'KidsPage',
  components: {
    PageSection,
    SectionText,
    ErrorState,
    LoadingState,
    Gallery
  },
  computed: {
    ...mapGetters('images', [ 'getImages', 'getImagesIsError','getImagesError', 'getImagesIsLoading'  ])
  },
  async created() {
    if(!this.$store.hasModule('images')) this.$store.registerModule('images', imagesStore);
    await this.fetchImages('teens');
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('images', [ 'fetchImages' ])
  }
};
</script>
